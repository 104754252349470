"use client"

import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Container, LeftArrow, NewText, useInViewConfig } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty, getUmbracoMediaUrl } from "utils"

const StyledContainer = styled.div`
  padding: 40px 0 40px 0;
  background: #2768a3;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 0px;
  }
`

const StyledDescriptionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 0px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`

const StyledMainContainer = styled.div<{
  $animateSecond: boolean
  $animateThird: boolean
  $animateFirst: boolean
  $animateFour: boolean
  $length: number
}>`
  position: relative;
  min-height: 550px;
  a {
    width: 245px;
    height: 245px;
  }

  ${(p) =>
    p.$animateFirst &&
    Array.from(
      { length: p.$length },
      (_, i) => `
    a:nth-child(${i + 1}) > div {
      top: ${20 + 12 * i}px;
      left: calc(50% - 125px);
    }
  `
    ).join("")}

  ${(p) =>
    p.$animateSecond &&
    Array.from(
      { length: p.$length },
      (_, i) => `
    a:nth-child(${i + 1}) > div {
      top: ${20 + 37 * i}px;
      left: calc(50% - 125px);
    }
  `
    ).join("")}

${(p) =>
    p.$animateThird &&
    Array.from(
      { length: p.$length },
      (_, i) => `
    a:nth-child(${i + 1}) > div {
      top: calc(100% - ${270 + 40 * i}px);
      left: ${10 + 7 * i}%;
    }
  `
    ).join("")}

${(p) =>
    p.$animateFour &&
    Array.from(
      { length: p.$length },
      (_, i) => `
    a:nth-child(${i + 1}) > div {
      top: ${Math.floor(i / 4) * 269}px;
      left: ${25 * (i % 4)}%;
    }
  `
    ).join("")}
`

const StyledLogoDiv = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  transition: bottom 400ms ease-in-out;
`

const StyledDescriptionBox = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  padding-top: 24px;
`

const StyledImageWrapp = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 12px;
  transition: transform 0.4s ease-in-out;
`

const StyledCard = styled.div<{
  $animateSecond: boolean
  $animateThird: boolean
  $index: number
  $animateFirst: boolean
  $animateFour: boolean
  $changeOpacity: boolean
}>`
  position: absolute;
  width: 245px;
  height: 245px;
  border-radius: 12px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  transform: ${(p) =>
    p.$animateThird
      ? "rotate(-24deg) skew(-28deg)"
      : p.$animateFour
      ? "rotate(0deg) skew(0deg)"
      : "rotate(-30deg) skew(30deg)"};
  opacity: ${(p) => (p.$changeOpacity ? 0.5 : 1)};
  transition: transform 600ms ease-in-out, all 600ms ease-in-out,
    opacity 400ms ease-in-out;
  overflow: hidden;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &:hover ${StyledLogoDiv} {
    bottom: 160px;
  }
  &:hover ${StyledDescriptionBox} {
    opacity: 1;
    visibility: visible;
  }
  &:hover ${StyledImageWrapp} {
    transform: scale(1.2);
  }
`

const StyledOverlay = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 1;
  border-radius: 12px;
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  bottom: 0px;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  z-index: 2;
  svg {
    path {
      fill: white;
    }
  }
  button {
    height: 48px;
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
`

type Props = {
  data: any
}
const AnimatedCardsNew = ({ data }: Props) => {
  const [animateFirst, setAnimateFirst] = useState(true)
  const [animateSecond, setAnimateSecond] = useState(false)
  const [animateThird, setAnimateThird] = useState(false)
  const [animateFour, setAnimateFour] = useState(false)
  const [changeOpacity, setChangeOpacity] = useState(false)

  const title = extractProperty(data, "title")
  const text = extractProperty(data, "text")
  const arms = extractProperty(data, "arms")

  const armsList = arms.map((prop: any) => {
    return {
      logoImage: extractProperty(prop, "logoImage"),
      armsText: extractProperty(prop, "armsText"),
      armLink: extractProperty(prop, "armLink"),
      image: extractProperty(prop, "image"),
    }
  })

  const { ref, inView } = useInView(useInViewConfig)

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setAnimateFirst(false)
        setAnimateSecond(true)
        setTimeout(() => {
          setChangeOpacity(true)
          setAnimateSecond(false)
          setAnimateThird(true)
        }, 800)
        setTimeout(() => {
          setChangeOpacity(false)
        }, 1200)
        setTimeout(() => {
          setAnimateThird(false)
          setAnimateFour(true)
        }, 1400)
        setTimeout(() => {
          setChangeOpacity(true)
        }, 1500)
        setTimeout(() => {
          setChangeOpacity(false)
        }, 1800)
      }, 200)
    }
  }, [inView])

  return (
    <StyledContainer ref={ref}>
      <Container>
        <NewText
          desktopFontSize={40}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          mobileFontSize={32}
          mobileFontWeight="400"
          mobileLineHeight={"112.5%"}
          component="h2"
          color={"white"}
        >
          {title}
        </NewText>

        <StyledDescriptionContainer>
          <NewText
            desktopFontSize={24}
            desktopFontWeight="400"
            desktopLineHeight="35px"
            mobileFontSize={18}
            mobileFontWeight="400"
            mobileLineHeight={"26px"}
            component="div"
            color={"white"}
            dangerouslySetInnerHTML={{ __html: text }}
            maxWidth={"800px"}
          />
        </StyledDescriptionContainer>
        <StyledMainContainer
          $animateSecond={animateSecond}
          $animateThird={animateThird}
          $animateFirst={animateFirst}
          $animateFour={animateFour}
          $length={armsList.length}
        >
          {armsList.map((arm: any, index: number) => {
            return (
              <Link
                key={index}
                href={arm.armLink[0].url}
                target={arm.armLink[0].target}
              >
                <StyledCard
                  style={{
                    zIndex: armsList.length - index,
                  }}
                  $animateSecond={animateSecond}
                  $animateThird={animateThird}
                  $animateFirst={animateFirst}
                  $index={index}
                  $animateFour={animateFour}
                  $changeOpacity={changeOpacity}
                >
                  <StyledImageWrapp>
                    <StyledOverlay />
                    <Image
                      src={getUmbracoMediaUrl(arm.image)}
                      fill
                      objectFit="cover"
                      alt=""
                      style={{ borderRadius: "12px" }}
                    />
                  </StyledImageWrapp>
                  <StyledInfoContainer>
                    <StyledLogoDiv>
                      <Image
                        src={getUmbracoMediaUrl(arm.logoImage)}
                        alt=""
                        objectFit="cover"
                        height={48}
                        width={130}
                        style={{ objectFit: "cover", objectPosition: "top" }}
                      />
                    </StyledLogoDiv>
                    <StyledDescriptionBox>
                      <NewText
                        component={"p"}
                        desktopFontSize={20}
                        color={sharedColors.white}
                        desktopLineHeight={1.2}
                        desktopFontWeight={400}
                      >
                        {arm.armsText}
                      </NewText>
                    </StyledDescriptionBox>
                    <IconButton>
                      <LeftArrow />
                    </IconButton>
                  </StyledInfoContainer>
                </StyledCard>
              </Link>
            )
          })}
        </StyledMainContainer>
      </Container>
    </StyledContainer>
  )
}

export default AnimatedCardsNew
