"use client"

import styled from "@emotion/styled"
import Image from "next/image"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import {
  Container,
  Desktop,
  Mobile,
  NewText,
  useInViewConfig,
  useTransitionUpStyles,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractProperty } from "utils"
import EnergyMapDesktop from "./EnergyMapDesktop"
import EnergyMapMobile from "./EnergyMapMobile"

type Props = {
  data: any
}

const StyledDiv = styled.div`
  background-color: #2768a3;
  width: 100%;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: auto;
    padding: 60px 0px 60px 0px;
  }
`

const StyledRichText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: ${sharedColors.white};
  padding-bottom: 40px;
  padding-right: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 25px;
    max-width: 650px;
    padding-right: 0px;
  }
  p {
    margin: 0px;
  }
  margin-top: 16px;
`

const StyledIamgeWrapper = styled.div`
  position: relative;
  aspect-ratio: 1040/515;
  margin-right: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 0px;
  }
`

const StyledBottomDiv = styled.div`
  padding-top: 32px;
  span {
    font-size: 16px;
    line-height: 20px;
    ${(props) => props.theme.breakpoints.down("sm")} {
      display: block;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
      line-height: 25px;
      margin-left: 8px;
      padding-top: 40px;
    }
  }
`

const StyledDot = styled.div<{ $background: string }>`
  background-color: ${(p) => p.$background};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`

const StyledLine = styled.div`
  height: 66px;
  width: 2px;
  background-color: ${sharedColors.white};
  margin-left: 40px;
  margin-right: 40px;
`

const StyledBottomDivWrapp = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  flex-wrap: wrap;
  ${(props) => props.theme.breakpoints.down("sm")} {
    row-gap: 24px;
  }
`

const StyledTextWrapp = styled.div`
  display: flex;
  align-items: center;
`

const StyledInnerWrapp = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 50%;
  }
`

const StyledContainer = styled(Container)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-right: 0px;
  }
`

function EnergyMap({ data }: Props) {
  const mapTitle = extractProperty(data, "mapTitle")
  const mapText = extractProperty(data, "mapText")
  const totalTitleText = extractProperty(data, "totalTitleText")
  const totalTitleNumber = extractProperty(data, "totalTitleNumber")
  const totalInOperation = extractProperty(data, "totalInOperation")
  const totalUnderConstruction = extractProperty(data, "totalUnderConstruction")
  const totalInInitiationAndDevelopment = extractProperty(
    data,
    "totalInInitiationAndDevelopment"
  )
  const globalTotal = extractProperty(data, "globalTotal")
  const countryItems = extractProperty(data, "countryItems")

  const { ref, inView } = useInView(useInViewConfig)
  const { ref: mapRef, inView: mapView } = useInView(useInViewConfig)

  const titleTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)

  return (
    <StyledDiv ref={ref}>
      <StyledContainer>
        <NewText
          component={"h2"}
          desktopFontSize={40}
          desktopLineHeight={"44px"}
          desktopFontWeight={400}
          mobileFontSize={32}
          mobileLineHeight={"36px"}
          color={sharedColors.white}
          cssString={styleObjectToCss(titleTransitionStyles)}
        >
          {mapTitle}
        </NewText>
        <StyledRichText
          style={{ ...descriptionTransitionStyles }}
          dangerouslySetInnerHTML={{ __html: mapText }}
        />
        <StyledIamgeWrapper ref={mapRef}>
          <Image src={"/images/Map.png"} fill alt="energy map" />
          <Desktop>
            <EnergyMapDesktop countryItems={countryItems} inView={mapView} />
          </Desktop>
        </StyledIamgeWrapper>
        <Mobile>
          <EnergyMapMobile countryItems={countryItems} />
        </Mobile>
        <StyledBottomDiv>
          <NewText
            component={"h3"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"36px"}
            color={sharedColors.white}
          >
            {totalTitleNumber}
            <span>{totalTitleText}</span>
          </NewText>
          <StyledBottomDivWrapp>
            <StyledInnerWrapp>
              <div>
                <StyledTextWrapp>
                  <StyledDot $background="#A6CF51" />
                  <NewText
                    component={"p"}
                    desktopFontSize={18}
                    desktopLineHeight={"26px"}
                    desktopFontWeight={400}
                    mobileFontSize={16}
                    mobileLineHeight={"20px"}
                    color={sharedColors.white}
                  >
                    בהפעלה
                  </NewText>
                </StyledTextWrapp>
                <NewText
                  component={"h3"}
                  desktopFontSize={32}
                  desktopLineHeight={"36px"}
                  desktopFontWeight={400}
                  mobileFontSize={24}
                  mobileLineHeight={"28px"}
                  color={sharedColors.white}
                >
                  {totalInOperation}
                </NewText>
              </div>

              <Desktop>
                <StyledLine />
              </Desktop>
            </StyledInnerWrapp>
            <StyledInnerWrapp>
              <div>
                <StyledTextWrapp>
                  <StyledDot $background="#56CCF2" />
                  <NewText
                    component={"p"}
                    desktopFontSize={18}
                    desktopLineHeight={"26px"}
                    desktopFontWeight={400}
                    mobileFontSize={16}
                    mobileLineHeight={"20px"}
                    color={sharedColors.white}
                  >
                    בהקמה
                  </NewText>
                </StyledTextWrapp>
                <NewText
                  component={"h3"}
                  desktopFontSize={32}
                  desktopLineHeight={"36px"}
                  desktopFontWeight={400}
                  mobileFontSize={24}
                  mobileLineHeight={"28px"}
                  color={sharedColors.white}
                >
                  {totalUnderConstruction}
                </NewText>
              </div>

              <Desktop>
                <StyledLine />
              </Desktop>
            </StyledInnerWrapp>
            <StyledInnerWrapp>
              <div>
                <StyledTextWrapp>
                  <StyledDot $background="#FFD910" />
                  <NewText
                    component={"p"}
                    desktopFontSize={18}
                    desktopLineHeight={"26px"}
                    desktopFontWeight={400}
                    mobileFontSize={16}
                    mobileLineHeight={"20px"}
                    color={sharedColors.white}
                  >
                    בייזום ופיתוח
                  </NewText>
                </StyledTextWrapp>
                <NewText
                  component={"h3"}
                  desktopFontSize={32}
                  desktopLineHeight={"36px"}
                  desktopFontWeight={400}
                  mobileFontSize={24}
                  mobileLineHeight={"28px"}
                  color={sharedColors.white}
                >
                  {totalInInitiationAndDevelopment}
                </NewText>
              </div>

              <Desktop>
                <StyledLine />
              </Desktop>
            </StyledInnerWrapp>
            <StyledInnerWrapp>
              <div>
                <StyledTextWrapp>
                  <NewText
                    component={"p"}
                    desktopFontSize={18}
                    desktopLineHeight={"26px"}
                    desktopFontWeight={400}
                    mobileFontSize={16}
                    mobileLineHeight={"20px"}
                    color={sharedColors.white}
                  >
                    סה״כ גלובלי
                  </NewText>
                </StyledTextWrapp>
                <NewText
                  component={"h3"}
                  desktopFontSize={32}
                  desktopLineHeight={"36px"}
                  desktopFontWeight={400}
                  mobileFontSize={24}
                  mobileLineHeight={"28px"}
                  color={sharedColors.white}
                >
                  {globalTotal}
                </NewText>
              </div>
            </StyledInnerWrapp>
          </StyledBottomDivWrapp>
        </StyledBottomDiv>
      </StyledContainer>
    </StyledDiv>
  )
}
export default EnergyMap
